import React from "react";

export default function SvgManager({ src, ...params }: any) {
  return (
    <svg
      className={params["icon"] || "icon"}
      viewBox={params["viewBox"] || "0 0 24 24"}
      fill={params["fill"] || "none"}
      stroke={params["stroke"] || "currentColor"}
      strokeWidth={params["strokeWidth"] || "2"}
      strokeLinecap={params["strokeLinecap"] || "round"}
      strokeLinejoin={params["strokeLinejoin"] || "round"}
    >
      <use xlinkHref={src} />
    </svg>
  );
}
