import React from "react";

import {motion} from "framer-motion";
import ContentHeader from "../structure/content_header";

export default function Support() {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 1}}
        >
            <ContentHeader title="Support"/>
            <p>
                Si vous avez la moindre question, contactez-nous à l'adresse suivante :{" "}
                <a href="mailto:support@friendsleague.be" className="basic--link">
                    support@friendsleague.be
                </a>
            </p>
        </motion.div>
    );
}
