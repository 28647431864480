import { baseEndpoints } from "./base";

export const APIMatches = baseEndpoints("games");

export interface MatchData {
  id: string;
  teamA: teamData;
  teamB: teamData;
  scoreTeamA: number;
  scoreTeamB: number;
  oddsVictoryA: number;
  oddsVictoryB: number;
  oddsDraw: number;
  matchDate: Date;
  step: keyof typeof matchStep;
  status: keyof typeof matchStatus;
  pool: keyof typeof poolLists;
}

export interface teamData {
  id: string;
  name: string;
  blazon: string;
}

export const defaultTeamData = {
  id: "",
  name: "",
  blazon: "",
};

export const defaultMatchData = {
  id: "",
  status: 1,
  teamA: defaultTeamData,
  teamB: defaultTeamData,
  scoreTeamA: 0,
  scoreTeamB: 0,
};

export const matchStep = [
  "Phase de poule",
  "1/8 de finale",
  "1/4 de finale",
  "1/2 de finale",
  "Petite finale",
  "Finale",
];

export const matchStatus = [
  "waiting",
  "playing",
  "finished",
  "extra_time",
  "penaltys",
  "half_time",
  "finished", //Bets computed
];

export const poolLists: { [key: string]: any } = {
  "": "",
  "pool a": "Poule A",
  "pool b": "Poule B",
  "pool c": "Poule C",
  "pool d": "Poule D",
  "pool e": "Poule E",
  "pool f": "Poule F",
};
