import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import AuthUser from "../../services/swr/user";
import ContentHeader from "../structure/content_header";
import useMatchList from "../../services/hook/useMatchList";
import MatchList from "../match/MatchList";
import LottieLoader from "../../utils/lottie";

export default function Matches() {
  const { data: matches, isLoading: isLoadingMatches } = useMatchList();

  const { isLoading, isError } = AuthUser();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isError) {
      history.push("/login");
    }
  }, [isError, isLoading, history]);

  if (isLoadingMatches) {
    return <LottieLoader />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
    >
      <ContentHeader title="Matches" />
      <MatchList matches={matches} />
    </motion.div>
  );
}
