import React from "react";

import { BrowserRouter } from "react-router-dom";

import Header from "./components/structure/header";
import Router from "./components/structure/router";

import "./styles/app.css";
import "./styles/friends_league.css";
import RulesLink from "./components/user/rulesLink";

export default function App() {
  return (
    <BrowserRouter>
      <RulesLink />
      <div className="container">
        <Header />
        <Router />
      </div>
    </BrowserRouter>
  );
}
