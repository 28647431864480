import React from "react";

import { motion } from "framer-motion";
import LeagueList from "../league/list";
import MatchTileList from "../match/MatchTileList";
import MyLeagueList from "../league/myleagues";
import HomeRanking from "../home/ranking";
import ContentHeader from "../structure/content_header";

export default function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
    >
      <ContentHeader title="" />
      <MatchTileList
        type="upcoming"
        tiles_type="normal"
        title="Liste des Matches à venir"
      />
      <MatchTileList
        type="results"
        tiles_type="tiny"
        title="Derniers résultats"
      />
      <MyLeagueList />
      <LeagueList item_size="tiny" />
      <HomeRanking />
    </motion.div>
  );
}
