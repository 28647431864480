import useSWR from "swr";

import axios from "axios";
import {removeToken} from "../../utils/storage";

const fetcherWithToken = (url: string, token: string) =>
    axios
        .get(url, {headers: {Authorization: "Bearer " + token}})
        .then((res: any) => res.data)
        .catch((error: any) => {
            if (error.response.status === 401) {
                removeToken();
            }
        });

export default function AuthUser() {
    const {data, error} = useSWR(
        [
            process.env.REACT_APP_API_URL + "users/me",
            localStorage.getItem("accessToken"),
        ],
        fetcherWithToken
    );

    return {
        auth_user: data,
        isLoading: !error && !data,
        isError: error,
    };
}
