import React, {useEffect, useState} from "react";
import {APILeagues, leagueData} from "../../services/api/leagues";
import UserRanking from "../user/ranking";
import AuthUser from "../../services/swr/user";
import LeagueJoin from "./join";
import LeagueLeave from "./leave";
import LeagueShare from "./share_btn";
import defaultAvatar from "../../assets/images/Logo.png";

export default function League({id}: any) {
    const [league, setLeague] = useState<leagueData | any>({});
    const {auth_user} = AuthUser();

    useEffect(() => {
        APILeagues.single(id).then((response: any) => {
            setLeague(response);
        });
    }, [id]);

    return (
        <div>
            <div className="league--header">
                <div>
                    <img
                        src={league.blazon || defaultAvatar}
                        alt={league.name}
                        className="league--blazon"
                    />
                </div>
                <div>
                    <h2>{league.name}</h2>
                    {league.price && <p className="league--price">{league.price}</p>}
                    <div className="league--options">
                        {auth_user && !league.isMember && <LeagueJoin league={league}/>}
                        {auth_user && league.isMember && league.user !== auth_user.id && <LeagueLeave league={league}/>}
                        {auth_user && league.user === auth_user.id && (
                            <a
                                href={"/leagues/edit/" + league.id}
                                className="basic--btn league--edit--btn"
                            >
                                Editer la league
                            </a>
                        )}
                        {league.isMember && <LeagueShare league={league}/>}
                    </div>
                </div>
            </div>
            <UserRanking users={league.members}/>
        </div>
    );
}
