import React, { useEffect, useState } from "react";
import { APIMatches, MatchData } from "../../services/api/matches";
import {
  APIBets,
  betData,
  bonusEmoji,
  defaultBetData,
} from "../../services/api/bets";
import BonusData from "../../services/api/bonus";
import AuthUser from "../../services/swr/user";
import { useHistory } from "react-router-dom";

interface AddBetProps {
  match: MatchData;
  onClose: () => void;
}
export default function AddBet({ match, onClose }: AddBetProps) {
  const [message, setMessage] = useState("");
  const [score_a, setScoreA] = useState<number>(0);
  const [score_b, setScoreB] = useState<number>(0);
  const [bonus_summary, setBonusSummary] = useState([]);
  const [bonus, setBonus] = useState<Array<BonusData>>([]);
  const [bonus_id, setBonusId] = useState("");
  const [bonus_active_type, setBonusActiveType] = useState(0);
  const [bet, setBet] = useState<betData>(defaultBetData);
  const { auth_user } = AuthUser();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(match).length !== 0) {
      APIMatches.single(match.id + "/user_bet")
        .then((response: any) => {
          setBet(response);
          setBonusId(response.bonus);
          setScoreA(response.scoreTeamA);
          setScoreB(response.scoreTeamB);
        })
        .catch((error: any) => {});
    }
  }, [match]);

  useEffect(() => {
    if (auth_user) {
      setBonusSummary(
        bonus.reduce((p: any, c: any) => {
          if (c.used === false || c.id === bet.bonus) {
            let type = c.type;
            if (!p.hasOwnProperty(type)) {
              p[type] = 0;
            }
            p[type]++;
          }
          return p;
        }, [])
      );
    }
  }, [bet, auth_user, bonus]);

  useEffect(() => {
    for (let i = 0; i < bonus.length; i++) {
      if (bonus[i].id === bet.bonus) {
        setBonusActiveType(bonus[i].type);
        return;
      }
    }
  }, [bonus, bet]);

  useEffect(() => {
    if (auth_user) {
      setBonus(auth_user.bonus);
    }
  }, [auth_user]);

  function addBet() {
    if (auth_user) {
      let form_data = {
        game: match.id,
        bonus: bonus_id,
        scoreTeamA: score_a.toString(),
        scoreTeamB: score_b.toString(),
      };
      if (bet.id !== "") {
        APIBets.update(bet.id, form_data)
          .then((response) => {
            onClose();
          })
          .catch((error: any) => {
            if (error.response) {
              // Request made and server responded
              setMessage(error.response.data.detail);
            }
          });
      } else {
        APIBets.create(form_data)
          .then((response) => {
            onClose();
          })
          .catch((error: any) => {
            if (error.response) {
              // Request made and server responded
              setMessage(error.response.data.detail);
            }
          });
      }
    } else {
      history.push("/login");
    }
  }

  const handleChangeBonus = (e: any) => {
    if (auth_user) {
      let type = e.target.value;
      setBonusActiveType(type);
      if (type === "0") {
        setBonusId("");
        return;
      }

      let default_bonus_id = bet.bonus;
      for (let i = 0; i < bonus.length; i++) {
        if (
          bonus[i].type.toString() === type.toString() &&
          (bonus[i].id === default_bonus_id || !bonus[i].used)
        ) {
          console.log(bonus[i]);
          setBonusId(bonus[i].id);
          return;
        }
      }
    }
  };

  const handleIncreaseScoreA = () => {
    setScoreA(score_a + 1);
  };

  const handleDecreaseScoreA = () => {
    if (score_a > 0) {
      setScoreA(score_a - 1);
    }
  };

  const handleIncreaseScoreB = () => {
    setScoreB(score_b + 1);
  };

  const handleDecreaseScoreB = () => {
    if (score_b > 0) {
      setScoreB(score_b - 1);
    }
  };

  return (
    <div className="bet--container">
      {message && <p className="error--msg">{message}</p>}
      <div className="bet--form">
        <div className="team--score--container">
          <button onClick={handleIncreaseScoreA} className="arrow--up"></button>
          <p className="team--score">{score_a}</p>
          <button
            onClick={handleDecreaseScoreA}
            className={`arrow--down ${score_a === 0 ? "inactive" : ""}`}
          ></button>
        </div>
        <div className="bonus--selector">
          <p>BONUS</p>
          <select
            name="bonus"
            onChange={handleChangeBonus}
            value={bonus_active_type}
          >
            <option value="0">{bonusEmoji[0]}</option>
            {bonus_summary.length > 0 &&
              bonus_summary.map((b, key): any => {
                return (
                  <option key={key} value={key}>
                    {bonusEmoji[key]}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="team--score--container">
          <button onClick={handleIncreaseScoreB} className="arrow--up"></button>
          <p className="team--score">{score_b}</p>
          <button
            onClick={handleDecreaseScoreB}
            className={`arrow--down ${score_b === 0 ? "inactive" : ""}`}
          ></button>
        </div>
      </div>
      <button className="basic--btn add--bet" onClick={addBet}>
        {auth_user ? "Valider mon paris" : "Se connecter"}
      </button>
    </div>
  );
}
