import React, { useEffect, useState } from "react";
import { APIUsers } from "../../services/api/users";
import { useHistory } from "react-router-dom";
import ContentHeader from "../structure/content_header";

export default function UserEdition() {
  const [user, setUser] = useState<any>(null);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");
  const history = useHistory();

  useEffect(() => {
    APIUsers.single("me").then((response: any) => {
      setUser(response);
    });
  }, []);

  useEffect(() => {
    setUsername(user?.username);
    setEmail(user?.email);
  }, [user]);

  const signin = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let form_data = new FormData();
    form_data.append("avatar", avatar);
    form_data.append("username", username);
    form_data.append("email", email);

    APIUsers.update(user.id, form_data)
      .then((response: any) => {
        history.push("/user/" + user.id);
      })
      .catch((error: any) => {
        if (error.response) {
          // Request made and server responded
          setMessage(error.response.data.detail);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleChangeUsername = (e: any) => {
    setUsername(e.target.value);
  };

  const handleChangeAvatar = (e: any) => {
    setAvatar(e.target.files[0]);
  };

  return (
    <div>
      <ContentHeader title="Edition" />
      {message && <p className="login--error_message">{message}</p>}
      <form onSubmit={signin} className="basic--form">
        <input
          type="text"
          placeholder="username"
          onChange={handleChangeUsername}
          value={username}
        />
        <input
          type="text"
          placeholder="email"
          onChange={handleChangeEmail}
          value={email}
        />

        <input
          type="file"
          placeholder="avatar"
          id="avatar"
          name="avatar"
          onChange={handleChangeAvatar}
        />
        <button className="basic--btn add--bet">Editer</button>
      </form>
    </div>
  );
}
