import React, {useEffect, useState} from "react";
import {userData} from "../../services/api/users";
import UserRank from "./user_rank";
import {APIMatches} from "../../services/api/matches";

export default function UserRanking({users}: any) {
    const [next_match, setNextMatch] = useState([]);

    useEffect(() => {
        APIMatches.index('?type=upcoming&page=1,1&ordering=match_date').then((response: any) => {
            if (response) {
                setNextMatch(response[0])
            }
        }).catch()
    }, [])

    const user_ranking = users?.map((user: userData, index: number) => (
        <UserRank key={index} user={user} position={index + 1} next_match={next_match}/>
    ));

    return (
        <div>
            <h1>Classement général</h1>
            <ul className="user--ranking">{user_ranking}</ul>
        </div>
    );
}
