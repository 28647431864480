import React from "react";
import defaultAvatar from "../../assets/images/Logo.png";

export default function LeagueBigItem({ league }: any) {
  return (
    <div className={`league--big--item ${league.isPrivate && "private"}`}>
      <a href={"/leagues/" + league.id} className="league--link">
        <img
          src={league.blazon || defaultAvatar}
          alt={league.name}
          className="league--blazon"
        />
        <div>
          <h2>{league.name}</h2>
          <span>{league.members.length} membres</span>
        </div>
      </a>
    </div>
  );
}
