import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  APIMatches,
  defaultTeamData,
  MatchData,
  matchStep,
  poolLists,
  teamData,
} from "../../services/api/matches";
import AddBet from "./add_bet";
import MatchOdd from "./odd";
import Loading from "../../shared/ui/Loading";

interface MatchProps {
  id: string;
  onClose: () => void;
}
export default function Match({ id, onClose }: MatchProps) {
  const [match, setMatch] = useState<MatchData | any>({});
  const history = useHistory();
  const [team_a, setTeamA] = useState<teamData>(defaultTeamData);
  const [team_b, setTeamB] = useState<teamData>(defaultTeamData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      APIMatches.single(id)
        .then((response: any) => {
          setIsLoading(false);
          setMatch(response);
          setTeamA(response.teamA);
          setTeamB(response.teamB);
        })
        .catch(() => {
          history.push("/404");
        });
    }
  }, [id, history]);

  const match_date = new Date(match.matchDate);

  return (
    <Loading isLoading={isLoading} color="#0A0A0BFF">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
      >
        <div className="match--container">
          <div className="match--info">
            {match.step === 0 ? (
              <>
                <span>{matchStep[match.step]}</span>
                <span className="match--pool">{poolLists[match.pool]}</span>
              </>
            ) : (
              <>
                <span>Phase finale</span>
                <span className="match--pool">{matchStep[match.step]}</span>
              </>
            )}
            <span className="match--hour">
              {match_date.toLocaleDateString("fr-BE", {
                year: undefined,
                month: "long",
                day: "2-digit",
              }) +
                " - " +
                match_date.getHours() +
                ":" +
                (match_date.getMinutes() < 10 ? "0" : "") +
                match_date.getMinutes()}
            </span>
          </div>
          <div className="match--teams">
            <div className="team match.team--a">
              <img src={team_a.blazon} alt={team_a.name} />
              <p>{team_a.name}</p>
            </div>

            <div className="match--score">
              <span>{match.scoreTeamA + " : " + match.scoreTeamB}</span>
            </div>
            <div className="team team--b">
              <img src={team_b.blazon} alt={team_b.name} />
              <p>{team_b.name}</p>
            </div>
          </div>
          <MatchOdd match={match} />
          <AddBet match={match} onClose={onClose} />
        </div>
      </motion.div>
    </Loading>
  );
}
