import React from "react";
import { bonusEmoji } from "../../services/api/bets";
import styled from "styled-components";

const Container = styled.ul`
  display: flex;
  padding: 0;
  margin: 6px 0;
`;
const Wrapper = styled.li<{ isBonusUsed: boolean }>`
  list-style: none;
  padding-right: 5px;
  opacity: ${({ isBonusUsed }) => (isBonusUsed ? 0.3 : 1)};
  font-size: 24px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export default function UserBonus({ user }: any) {
  const bonusList = user.bonus?.map((bonus: any) => (
    <Wrapper isBonusUsed={bonus.used}>{bonusEmoji[bonus.type]}</Wrapper>
  ));

  return <Container>{bonusList}</Container>;
}
