import React, {useEffect, useState} from "react";
import {apiClient} from "../../services/api/base";
import {useHistory} from "react-router-dom";
import {removeToken} from "../../utils/storage";

export default function ChangePassword() {
    const params = new URLSearchParams(window.location.search);
    const [message, setMessage] = useState('');
    const [token, setToken] = useState(params.get('token') || '');
    const [new_password, setNewPassword] = useState('');
    const history = useHistory();

    const reset_password_token = (e: React.SyntheticEvent) => {
        e.preventDefault();
        removeToken();
        setMessage("");

        apiClient
            .post("auth/change_password", {
                token: token,
                new_password: new_password
            })
            .then((response: any) => {
                localStorage.setItem("accessToken", response.access);
                localStorage.setItem("refreshToken", response.refresh);

                history.push("/home");
            })
            .catch((error: any) => {
                if (error.response) {
                    // Request made and server responded
                    setMessage(error.response.data.detail);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            });
    };

    const handleChangeToken = (e: any) => {
        setToken(e.target.value);
    };

    const handleChangeNewPassword = (e: any) => {
        setNewPassword(e.target.value);
    };


    return (
        <div>
            <h1>Demande de token pour changer de password</h1>
            {message && <p className="error--msg">{message}</p>}
            <form onSubmit={reset_password_token} className="basic--form">
                <input
                    type="text"
                    name="email"
                    placeholder="token"
                    value={token}
                    onChange={handleChangeToken}
                />
                <input
                    type="password"
                    name="new_password"
                    placeholder="Nouveau mot de passe"
                    value={new_password}
                    onChange={handleChangeNewPassword}
                />
                <button className="basic--btn add--bet">Se connecter</button>
            </form>
        </div>
    );
}
