import LottieLoader from "../../../utils/lottie";
import { ReactNode } from "react";

export interface LoadingProps {
  isLoading?: boolean;
  color?: string;
  children?: ReactNode;
}

export default function Loading({ isLoading, color, children }: LoadingProps) {
  if (isLoading) {
    return <LottieLoader color={color} />;
  }
  return <>{children}</>;
}
