import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AuthUser from "../../services/swr/user";
import {APILeagues, leagueData} from "../../services/api/leagues";
import ContentHeader from "../structure/content_header";

export default function LeagueEdition({match}: any) {
    const league_id = match.params.id;
    const [league, setLeague] = useState<leagueData>();
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [price, setPrice] = useState("");
    const [password, setPassword] = useState("");
    const [pseudo, setPseudo] = useState("");
    const [blazon, setBlazon] = useState("");
    const history = useHistory();
    const {auth_user, isLoading, isError} = AuthUser();

    useEffect(() => {
        APILeagues.single(league_id).then((response: any) => {
            setPrice(response.price);
            setName(response.name);
            setLeague(response);
            for (let i = 0; i < response.members.length; i++) {
                if (auth_user && response.members[i].user === auth_user.id) {
                    setPseudo(response.members[i].pseudo);
                }
            }
        });
    }, [auth_user, league_id]);

    useEffect(() => {
        if (!isLoading && isError) {
            history.push("/login");
        }
    }, [isLoading, isError, history]);

    const create_league = (e: React.SyntheticEvent) => {
        e.preventDefault();
        let form_data = new FormData();
        if (blazon !== "") {
            form_data.append("blazon", blazon);
        }
        if (password !== "") {
            form_data.append("password", password);
        }
        form_data.append("name", name);
        form_data.append("price", price);
        form_data.append("pseudo", pseudo);

        APILeagues.update(league_id, form_data)
            .then((response: any) => {
                history.push("/leagues/" + response.id);
            })
            .catch((error: any) => {
                if (error.response) {
                    // Request made and server responded
                    setMessage(error.response.data.detail);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            });
    };

    const handleChangePrice = (e: any) => {
        setPrice(e.target.value);
    };

    const handleChangePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    };

    const handleChangeBlazon = (e: any) => {
        setBlazon(e.target.files[0]);
    };

    return (
        <div>
            <ContentHeader
                title={league ? 'Editer "' + league.name + '"' : "Editer ma league"}
            />
            {message && <p className="login--error_message">{message}</p>}
            <form onSubmit={create_league} className="basic--form">
                <input
                    type="text"
                    placeholder="name"
                    name="name"
                    value={name}
                    required
                    onChange={handleChangeName}
                />
                <input
                    type="text"
                    placeholder="price"
                    name="price"
                    value={price}
                    onChange={handleChangePrice}
                />
                {league && league.isPrivate && (
                    <div>
                        <input
                            type="password"
                            placeholder="password"
                            onChange={handleChangePassword}
                        />
                        <input type="password" placeholder="confirmation du password"/>
                    </div>
                )}
                <input
                    type="file"
                    placeholder="blazon"
                    name="blazon"
                    onChange={handleChangeBlazon}
                />
                <button className="basic--btn add--bet">Editer</button>
            </form>
        </div>
    );
}
