import {APILeagueUsers, leagueUserData} from "../../services/api/league_users";
import AuthUser from "../../services/swr/user";
import {useHistory} from "react-router-dom";

export default function LeagueJoin({league: leagueData}: any) {
    const history = useHistory();
    const {auth_user} = AuthUser();

    const leave = (e: any) => {
        let league_user = leagueData.members.find((item: leagueUserData) => item.user === auth_user.id);
        e.preventDefault();

        APILeagueUsers.remove(
            league_user.id
        ).then((response: any) => {
            history.push("/home");

        }).catch((error: any) => {
        });
    };


    return (
        <div>
            <button
                className={`basic--btn league--join--btn`}
                onClick={leave}
            >
                Quitter la league
            </button>
        </div>
    );
}
