import axios from "axios";
import validToken from "../../utils/storage";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
    },
});

apiClient.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: {Authorization: validToken() ? "Bearer " + validToken() : ""},
        };
    },
    (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
    (response) => response.data,
    async (error) => Promise.reject(error)
);

function baseEndpoints(base_url: string) {
    return {
        index: (filters: string = "") => apiClient.get(base_url + filters),
        single: (id: string) => apiClient.get(`${base_url}/${id}`),
        create: (params: object) => apiClient.post(base_url, params),
        update: (id: string, params: object) =>
            apiClient.put(`${base_url}/${id}`, params),
        remove: (id: string) => apiClient.delete(`${base_url}/${id}`),
    };
}

export {baseEndpoints, apiClient};
