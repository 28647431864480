import React, { useEffect, useState } from "react";
import AuthUser from "../../services/swr/user";

export default function RulesLink() {
  const [isClosed, setIsClosed] = useState<boolean>(true);
  const { isLoading, isError } = AuthUser();

  useEffect(() => {
    let rules_read = localStorage.getItem("rules_read");
    if (!rules_read && !isLoading && !isError) {
      setIsClosed(false);
    }
  }, [isLoading, isError]);

  const close = () => {
    localStorage.setItem("rules_read", "true");
    setIsClosed(true);
  };

  return !isClosed ? (
    <div className="rules--container">
      <p>
        Veuillez lire les règles du jeux avant de commencer à jouer :{" "}
        <a href="/rules" className="basic--link">
          Règles du jeu
        </a>
      </p>
      <button onClick={close}>X</button>
    </div>
  ) : (
    <div></div>
  );
}
