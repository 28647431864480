import React, { useEffect, useState } from "react";
import { APILeagues, leagueData } from "../../services/api/leagues";
import LeagueBigItem from "./big_item";
import LeagueTinyItem from "./tiny_item";
import LottieLoader from "../../utils/lottie";

export default function LeagueList({ item_size }: any) {
  const [leagues, setLeagues] = useState([]);
  const [search_key, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let pagination = item_size !== "big" ? "&page=1,10" : "";
    APILeagues.index("?search=" + search_key + pagination).then(
      (response: any) => {
        setLoading(false);
        setLeagues(response);
      }
    );
  }, [item_size, search_key]);

  const league_list = leagues.map((league: leagueData, index) =>
    item_size === "big" ? (
      <LeagueBigItem key={index} league={league} />
    ) : (
      <LeagueTinyItem key={index} league={league} />
    )
  );

  const handleChange = (e: any) => {
    setSearchKey(e.target.value);
  };

  return (
    <div>
      {item_size === "big" && (
        <div>
          <a href="/leagues/create" className="basic--btn">
            Ajouter une league
          </a>
          <div className="search--box">
            <input
              value={search_key}
              onChange={handleChange}
              placeholder="Chercher une league..."
            />
          </div>
        </div>
      )}
      {loading && <LottieLoader />}
      <div className="leagues--list__complete">
        {item_size === "big" ? (
          league_list
        ) : (
          <div>
            <h2>Leagues</h2>
            <div className="horizontal--container leagues--list">
              {league_list}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
