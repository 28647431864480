import React, { useEffect, useState } from "react";

import {
  APIMatches,
  defaultTeamData,
  matchStatus,
  teamData,
} from "../../services/api/matches";
import { betData, defaultBetData } from "../../services/api/bets";

export default function MatchTinyTile({ match }: any) {
  const [team_a, setTeamA] = useState<teamData>(defaultTeamData);
  const [team_b, setTeamB] = useState<teamData>(defaultTeamData);
  const [bet, setBet] = useState<betData>(defaultBetData);

  useEffect(() => {
    setTeamA(match.teamA);
    setTeamB(match.teamB);
    APIMatches.index("/" + match.id + "/user_bet").then((response: any) => {
      setBet(response);
    });
  }, [match]);

  return (
    <div
      className={
        "match--tile match--tiny_tile" +
        (matchStatus[match.status] === "playing" ? " live" : "")
      }
    >
      <div className="match--teams">
        <div className="team team--a">
          <img src={team_a.blazon} alt={team_a.name} />
        </div>
        <div className="match--score">
          {match.scoreTeamA} - {match.scoreTeamB}
        </div>
        <div className="team team--b">
          <img src={team_b.blazon} alt={team_b.name} />
        </div>
      </div>
      <div className="bet--result">
        <span className="bet-eval">
          🎯 {bet.scoreTeamA} - {bet.scoreTeamB}
        </span>
        <span className="bet--points">{bet.points} pts</span>
      </div>
    </div>
  );
}
