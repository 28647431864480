import {baseEndpoints} from "./base";
import {APIUsers} from "./users";

export const APIAuth = baseEndpoints("auth");

export default function isLoggedIn() {
    let isLoggedIn = false;

    APIUsers.single("me").then((response: any) => {
        isLoggedIn = true;
    });

    return isLoggedIn;
}

export function AuthLogout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
}

export function AuthCreateResetPasswordToken() {

}