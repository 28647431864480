import React from "react";
import SvgManager from "../../utils/svg_manager";
import DefsSVG from "../../assets/images/defs.svg";

export default function Contact() {
    return (
        <section className="section section__contact hide__mobile">
            <strong className="title title--small">Contact</strong>

            <ul className="contact">
                <li className="contact__el contact__el--mail">
                    <a href="mailto:support@friendsleague.be">
                        <SvgManager src={DefsSVG + "#feather-mail"}/>
                        support@friendsleague.be
                    </a>
                </li>
                <li className="contact__el contact__el--tel">
                    <a href="https://web-cloud.be" target="_blank" rel="noreferrer">
                        <SvgManager src={DefsSVG + "#feather-paperclip"}/>
                        Webcloud
                    </a>
                </li>
            </ul>
        </section>
    );
}
