import {baseEndpoints} from "./base";
import {leagueUserData} from "./league_users";

export const APILeagues = baseEndpoints("leagues");

export interface leagueData {
    id: string;
    name: string;
    price: string;
    blazon: string;
    avatarUrl: string;
    isPrivate: boolean;
    isMember: boolean;
    members: leagueUserData[];
}
