import React, {useEffect, useRef, useState} from 'react';

interface LazyBackgroundProps {
    src: string;
    className?: string;
    children?: React.ReactNode;
}

const LazyBackground: React.FC<LazyBackgroundProps> = ({src, className, children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.disconnect();
                    }
                });
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div
            ref={ref}
            className={className}
            style={{
                backgroundImage: isVisible ? `url(${src})` : 'none',
            }}
        >
            {children}
        </div>
    );
};

export default LazyBackground;
