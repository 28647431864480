import { baseEndpoints } from "./base";

export const APILeagueUsers = baseEndpoints("league_users");

export interface leagueUserData {
  id: string;
  pseudo: string;
  score: number;
  league: string;
  user: string;
}
