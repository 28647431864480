import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import defaultAvatar from "../../assets/images/Logo.png";
import BetTile from "../bet/tile";
import {APIUsers} from "../../services/api/users";
import {betData} from "../../services/api/bets";

export default function UserRank({user, position, next_match}: any) {
    const user_pseudo: string = user.pseudo || user.username;
    const user_id: string = user.email ? user.id : user.user;
    const history = useHistory();
    const [bet, setBet] = useState(null);

    const goToUser = () => {
        history.push("/user/" + user_id);
    };

    useEffect(() => {
        APIUsers.index('/' + user_id + "/bets").then((response: any) => {
            let bet = response.find((bet: betData) => bet.game === next_match.id);
            setBet(bet);
        }).catch();
    }, [user]);


    return (
        <li key={user.id} onClick={goToUser}>
            <div style={{display: 'flex'}}>
                <div className="user--img">
                    <img src={user.avatar || defaultAvatar} alt={user_pseudo}/>
                    <span className="user--position">{position}</span>
                </div>
                <div className="user--name">
                    <p>
                        {user_pseudo} <br/>
                        <span className="points">{user.score} pts</span>
                    </p>
                </div>
            </div>
            {bet ? (<BetTile bet={bet} with_result={false}/>) : ''}
        </li>
    );
}
