import { useEffect, useState } from "react";
import { APIMatches, MatchData } from "../api/matches";

export default function useMatchList() {
  const [data, setData] = useState<MatchData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    APIMatches.index()
      .then((response: any) => {
        setIsLoading(false);
        setData(response);
      })
      .catch((error) => {
        console.log("Fetch error: ", error);
        setError("Something went wrong");
      });
  }, []);

  return {
    data,
    isLoading,
    error,
  };
}
