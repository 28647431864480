import {APILeagueUsers} from "../../services/api/league_users";
import {useState} from "react";
import AuthUser from "../../services/swr/user";

export default function LeagueJoin({league}: any) {
    const {auth_user} = AuthUser();
    const [toggle, setToggle] = useState(false);
    const [pseudo, setPseudo] = useState(auth_user.username);
    const [password, setPassword] = useState(null);
    const [message, setMessage] = useState(null);

    const join = (e: any) => {
        setMessage(null);
        e.preventDefault();
        APILeagueUsers.create({
            league: league.id,
            pseudo: pseudo,
            password: password,
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error: any) => {
                if (error.response) {
                    setMessage(error.response.data.fields);
                }
            });
    };

    const toggleJoinForm = () => {
        setToggle(!toggle);
    };

    const handleChangePassword = (e: any) => {
        setPassword(e.target.value);
    };
    const handleChangePseudo = (e: any) => {
        setPseudo(e.target.value);
    };

    return (
        <div>
            <button
                className={`basic--btn league--join--btn ${
                    league.isPrivate && "private"
                }`}
                onClick={toggleJoinForm}
            >
                Rejoindre la league
            </button>
            {toggle && (
                <div className="league--join--container">
                    {message && <p className="error--msg">{message}</p>}
                    <form className="basic--form" onSubmit={join}>
                        <p>Mon pseudo dans la league</p>
                        <input
                            type="text"
                            name="pseudo"
                            placeholder="Pseudo"
                            onChange={handleChangePseudo}
                            defaultValue={auth_user.username}
                        />
                        {league.isPrivate && (
                            <input
                                type="password"
                                placeholder="Mot de passe"
                                onChange={handleChangePassword}
                            />
                        )}
                        <button className="basic--btn">Rejoindre la league</button>
                    </form>
                </div>
            )}
        </div>
    );
}
