import React from "react";

import Navigation from "./navigation";
import Contact from "./contact";

export default function Header() {
  return (
    <header className="section section__header">
      <h1 className="title title--logo hide__mobile">
        <a href="/">Friendsleague</a>
      </h1>
      <Navigation />
      <Contact />
    </header>
  );
}
