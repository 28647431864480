import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import {
  APIMatches,
  defaultTeamData,
  matchStep,
  poolLists,
  teamData,
} from "../../services/api/matches";
import AuthUser from "../../services/swr/user";
import { betData, bonusEmoji } from "../../services/api/bets";
import { formatDate } from "../../utils/date";
import MatchModal from "./MatchModal";

export default function MatchTile({ match }: any) {
  const [team_a, setTeamA] = useState<teamData>(defaultTeamData);
  const [team_b, setTeamB] = useState<teamData>(defaultTeamData);
  const [userBet, setUserBet] = useState<betData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { auth_user } = AuthUser();

  useEffect(() => {
    setIsLoading(true);
    APIMatches.index("/" + match.id + "/user_bet")
      .then((response: any) => {
        setIsLoading(false);
        setUserBet(response);
      })
      .catch(() => {});
  }, [match]);

  useEffect(() => {
    setTeamA(match.teamA);
    setTeamB(match.teamB);
  }, [match]);

  const match_date = new Date(match.matchDate);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    window.location.reload(); // TODO check for a better way to reload the data
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
      >
        <div className="match--tile">
          {match.step === 0 ? (
            <div className="match--info">
              <span>{matchStep[match.step]}</span>
              <span className="match--pool">{poolLists[match.pool]}</span>
            </div>
          ) : (
            <div className="match--info">
              <span>Phase finale</span>
              <span className="match--pool">{matchStep[match.step]}</span>
            </div>
          )}
          <div className="match--teams">
            <div className="team team--a">
              <img src={team_a.blazon} alt={team_a.name} />
              <span>{team_a.name}</span>
            </div>
            <div className="match--date">
              <span className="match--hour">
                {match_date.getHours() +
                  ":" +
                  (match_date.getMinutes() < 10 ? "0" : "") +
                  match_date.getMinutes()}
              </span>
              <br />
              <span className="match--day">{formatDate(match_date)}</span>
            </div>
            <div className="team team--b">
              <img src={team_b.blazon} alt={team_b.name} />
              <span>{team_b.name}</span>
            </div>
          </div>
          <button
            className="basic--btn add--bet"
            onClick={() => setIsModalOpen(true)}
          >
            {auth_user && !isLoading ? (
              <p className="text--btn">
                Changer mon pari ({userBet?.scoreTeamA} - {userBet?.scoreTeamB})
                {userBet?.bonusType && ` x ${bonusEmoji[userBet.bonusType]}`}
              </p>
            ) : (
              "Parier maintenant"
            )}
          </button>
        </div>
      </motion.div>
      {isModalOpen && (
        <MatchModal matchId={match.id} onClose={handleCloseModal} />
      )}
    </>
  );
}
