import React from "react";

import {AnimatePresence} from "framer-motion";
import {Route, Switch} from "react-router-dom";

import Leagues from "../contents/leagues";
import Home from "../contents/home";
import UserLogin from "../user/login";
import UserSignin from "../user/signin";
import Matches from "../contents/matches";
import Support from "../contents/support";
import Rules from "../contents/rules";
import PrivacyPolicy from "../contents/privacy_policy";
import Footer from "./footer";
import UserEdition from "../user/edition";
import UserRetrieve from "../user/retrieve";
import LeagueForm from "../league/form";
import LeagueEdition from "../league/edition";
import Apps from "../contents/apps";
import ResetPasswordToken from "../user/reset_password_token";
import ChangePassword from "../user/change_password";

function NoMatch() {
    return <h1>404</h1>;
}

export default function Router() {
    return (
        <section className="page">
            <div className="page__scroll">
                <AnimatePresence>
                    <Switch>
                        <Route path="/apps" exact component={Apps}/>
                        <Route path="/support" exact component={Support}/>
                        <Route path="/rules" exact component={Rules}/>
                        <Route path="/privacy_policy" exact component={PrivacyPolicy}/>
                        <Route path="/matches" exact component={Matches}/>
                        <Route path="/leagues/edit/:id" exact component={LeagueEdition}/>
                        <Route path="/leagues/create" exact component={LeagueForm}/>
                        <Route path="/leagues/:id" exact component={Leagues}/>
                        <Route path="/leagues" exact component={Leagues}/>
                        <Route path="/home" exact component={Home}/>
                        <Route path="/user/edit" exact component={UserEdition}/>
                        <Route path="/signin" exact component={UserSignin}/>
                        <Route path="/login" exact component={UserLogin}/>
                        <Route path="/user/:id" exact component={UserRetrieve}/>
                        <Route
                            path="/reset_password_token"
                            exact
                            component={ResetPasswordToken}
                        />
                        <Route path="/change_password" exact component={ChangePassword}/>
                        <Route path="/" exact component={Home}/>
                        <Route component={NoMatch}/>
                    </Switch>
                </AnimatePresence>
                <Footer/>
            </div>
        </section>
    );
}
