import React, {useState} from "react";
import {APIUsers} from "../../services/api/users";
import {useHistory} from "react-router-dom";
import {apiClient} from "../../services/api/base";

export default function UserSignin() {
    const [username, setUsername] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [avatar, setAvatar] = useState("");
    const history = useHistory();

    const signin = (e: React.SyntheticEvent) => {
        setMessage("");
        e.preventDefault();
        let form_data = new FormData();
        form_data.append("avatar", avatar);
        form_data.append("username", username);
        form_data.append("password", password);
        form_data.append("email", email);

        APIUsers.create(form_data)
            .then((response: any) => {
                handleLogin()
            })
            .catch((error: any) => {
                if (error.response) {
                    // Request made and server responded
                    setMessage(error.response.data.detail);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }

            });
    };

    const handleLogin = () => {
        apiClient
            .post("auth/token/", {
                email: email,
                password: password,
            })
            .then((response: any) => {
                localStorage.setItem("accessToken", response.access);
                localStorage.setItem("refreshToken", response.refresh);

                history.push("/home");
            })
            .catch((error: any) => {
            });
    }

    const handleChangeEmail = (e: any) => {
        setEmail(e.target.value);
    };

    const handleChangePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const handleChangeUsername = (e: any) => {
        setUsername(e.target.value);
    };

    const handleChangeAvatar = (e: any) => {
        setAvatar(e.target.files[0]);
    };

    return (
        <div>
            <h1>Inscription</h1>
            {message && <p className="error--msg">{message}</p>}
            <form onSubmit={signin} className="basic--form">
                <input
                    type="text"
                    placeholder="username"
                    onChange={handleChangeUsername}
                />
                <input type="email" placeholder="email" onChange={handleChangeEmail}/>
                <input
                    type="password"
                    placeholder="password"
                    onChange={handleChangePassword}
                />
                <input type="password" placeholder="confirmation du password"/>
                <input
                    type="file"
                    placeholder="avatar"
                    name="avatar"
                    onChange={handleChangeAvatar}
                />
                <button className="basic--btn add--bet">S'inscrire</button>
            </form>
        </div>
    );
}
