import React, {useEffect, useState} from "react";
import {leagueData} from "../../services/api/leagues";
import {APIUsers} from "../../services/api/users";
import AuthUser from "../../services/swr/user";
import styled from "styled-components";
import LazyBackground from "../../utils/images";

const MoreCard = styled.a`
    display: block;
    width: 190px;
    height: 150px;
    background-image: linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    position: relative;
    border-radius: var(--main-radius);
    text-decoration: none;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 20px;
    padding: 12px 0 8px 0;
`;
const PlusButton = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    padding: 10px;
    font-size: 20px;
    color: var(--color-text);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
`;
const Text = styled.span`
    padding: 10px 0 0 0;
    font-size: 16px;
    color: var(--color-text);
`;
const text = "Rejoignez une league dès maintenant!";
export default function MyLeagueList() {
    const [leagues, setLeagues] = useState([]);
    const {auth_user} = AuthUser();

    useEffect(() => {
        APIUsers.single("me/joined_leagues").then((response: any) => {
            setLeagues(response);
        });
    }, []);

    return (
        <div>
            {auth_user && leagues.length > 0 ? (
                <div>
                    <h2>Mes leagues</h2>
                    <div className="horizontal--container leagues--list">
                        {leagues.map((league: leagueData) => (
                            <LazyBackground
                                src={league.blazon}
                                key={league.id}
                            >
                                <a href={"/leagues/" + league.id} className="league--link">
                                    <span>{league.name}</span>
                                </a>
                            </LazyBackground>
                        ))}
                        <div>
                            <MoreCard href="/leagues">
                                <Wrapper>
                                    <PlusButton>+</PlusButton>
                                    <Text>{text}</Text>
                                </Wrapper>
                            </MoreCard>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="cta--auth">
                    <a href="/leagues">
                        <p className="cta--more">
                            <span>+</span>
                        </p>
                        <p className="cta--text">{text}</p>
                    </a>
                </div>
            )}
        </div>
    );
}
