import React from "react";
import defaultAvatar from "../../assets/images/Logo.png";
import LazyBackground from "../../utils/images";

export default function LeagueTinyItem({league}: any) {
    return (
        <LazyBackground
            key={league.id}
            src={league.blazon}
        >
            <a href={"leagues/" + league.id} className="league--link">
                <span>{league.name}</span>
            </a>
        </LazyBackground>
    );
}
