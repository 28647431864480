import React, {useEffect} from "react";

import {motion} from "framer-motion";
import ContentHeader from "../structure/content_header";

export default function Rules() {
    // Save this page as a read page
    useEffect(() => {
        localStorage.setItem("rules_read", "true");
    }, []);

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 1}}
        >
            <ContentHeader title="Règles du jeu"/>
            <div className="content--rules">
                <p>Le principe est simple :</p>
                <ul>
                    <li>Vous créez un compte</li>
                    <li>Vous créez ou rejoignez une league</li>
                    <li>
                        Pariez sur les matchs pour gagner le max de points et gagnez le prix
                        de votre league.
                    </li>
                </ul>

                <p>Il y a différentes possibilités de gagner de points lors de la fin de chaque match:</p>

                <ol>
                    <li>⚽️ La base de la base du jeux, vous devez devinez qui sera le gagnant ou si le résultat sera un
                        match nul. Si vous y parvenez, vous gagnez les points liés à votre paris!
                    </li>
                    <li>🎲 Nous mettons à votre disposition un certains nombres de bonus qui multiplieront le score du
                        paris.
                        Il y a deux types de bonus:
                        <ol>
                            <li>2️⃣ : signifie que vous pouvez multiplier votre score par 2!</li>
                            <li>3️⃣ : signifie que vous pouvez multiplier votre score par 3!!</li>
                        </ol>
                        Attention, vous n'avez qu'un nombre limité de bonus, faites-en bon usage.
                    </li>
                    <li>
                        🥅 Si vous parvenez à deviner le bon nombre de goals, vous obtiendrez la moitié de ce total de
                        but en plus de vos points, et ce, même si vous ne devinez pas le bon résultat!
                    </li>
                    <li>
                        ✨ Vous avez le score parfait?! Vous recevez 2 points supplémentaires!!
                    </li>
                </ol>
                <p>
                    ⚠️ <b>Attention!</b> Vous ne pouvez pas parier tant que vous n'êtes pas dans une
                    league.
                </p>
            </div>
        </motion.div>
    );
}
