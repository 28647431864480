import { ReactNode, useRef } from "react";
import styled from "styled-components";
import useClickOutside from "../../../utils/useClickOutside";

const RADIUS = "5px";

const Container = styled.div`
  position: absolute;
  z-index: 1;
  right: 5px;
  min-width: 230px;
  width: auto;
  margin-top: 6px;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  transition: all linear 0.3s;
  border-radius: ${RADIUS};
`;
const Wrapper = styled.ul`
  margin: unset;
  padding: unset;
  list-style: none;
`;

interface DropdownProps {
  children: ReactNode;
  onClose: () => void;
}

export default function Dropdown({ children, onClose }: DropdownProps) {
  const ref = useRef(null);

  useClickOutside(ref, onClose);

  return (
    <Container ref={ref}>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
}
