import React, { useEffect, useState } from "react";
import { APIUsers, userData } from "../../services/api/users";
import UserRanking from "../user/ranking";

export default function HomeRanking() {
  const [users, setUsers] = useState<Array<userData>>([]);

  useEffect(() => {
    APIUsers.index("?ordering=-score&page=1,10").then((response: any) => {
      setUsers(response);
    });
  }, []);

  return <UserRanking users={users} />;
}
