import Modal from "../../shared/ui/Modal";
import Match from "./retrieve";
import React from "react";

interface MatchModalProps {
  matchId: string;
  onClose: () => void;
}

export default function MatchModal({ matchId, onClose }: MatchModalProps) {
  return (
    <Modal width="800px" onClose={onClose}>
      <Match id={matchId} onClose={onClose} />
    </Modal>
  );
}
