import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {apiClient} from "../../services/api/base";

export default function UserLogin() {
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const login = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setMessage("");
        apiClient
            .post("auth/token/", {
                email: email,
                password: password,
            })
            .then((response: any) => {
                localStorage.setItem("accessToken", response.access);
                localStorage.setItem("refreshToken", response.refresh);
                history.goBack();
            })
            .catch((error: any) => {
                if (error.response) {
                    // Request made and server responded
                    setMessage(error.response.data.detail);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            });
    };

    const handleChangeEmail = (e: any) => {
        setEmail(e.target.value);
    };

    const handleChangePassword = (e: any) => {
        setPassword(e.target.value);
    };

    return (
        <div>
            <h1>Connexion</h1>
            {message && <p className="error--msg">{message}</p>}
            <form onSubmit={login} className="basic--form">
                <input
                    type="email"
                    name="email"
                    placeholder="email"
                    value={email}
                    onChange={handleChangeEmail}
                />
                <input
                    type="password"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={handleChangePassword}
                />
                <button className="basic--btn add--bet">Se connecter</button>
            </form>

            <p><a href="/signin" className="basic--link auth--signin--link">
                Pas encore de compte? Inscrivez-vous!
            </a></p>

            <p><a href="/reset_password_token" className="basic--link auth--signin--link">
                Mot de passe oublié!
            </a></p>
        </div>
    );
}
