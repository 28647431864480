import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIUsers, userData } from "../../services/api/users";
import AuthUser from "../../services/swr/user";
import ContentHeader from "../structure/content_header";
import { leagueData } from "../../services/api/leagues";
import LeagueBigItem from "../league/big_item";
import UserBonus from "./bonus";
import UserBets from "./bets";
import LottieLoader from "../../utils/lottie";
import defaultAvatar from "../../assets/images/Logo.png";
import Loading from "../../shared/ui/Loading";
import styled from "styled-components";

const PointWrapper = styled.div`
  margin: 0 0 12px 0;
  @media (max-width: 500px) {
    margin: 0 0 4px 0;
  }
`;
const Title = styled.h2`
  margin: 0;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
const Points = styled.div`
  font-size: 24px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
const BonusWrapper = styled.div``;

export default function UserRetrieve() {
  const { id }: any = useParams();
  const [user, setUser] = useState<userData | any>({});
  const { auth_user, isLoading, isError } = AuthUser();
  const [leagues, setLeagues] = useState([]);

  useEffect(() => {
    if (id === "me" && !isLoading && !isError) {
      setUser(auth_user);
    } else {
      APIUsers.single(id).then((response: any) => {
        setUser(response);
      });
    }
  }, [id, auth_user, isLoading, isError]);

  useEffect(() => {
    APIUsers.single(user.id + "/joined_leagues")
      .then((response: any) => {
        setLeagues(response);
      })
      .catch();
  }, [user]);

  const league_list = leagues.map((league: leagueData, index) => (
    <LeagueBigItem key={index} league={league} />
  ));

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  return (
    <div>
      <ContentHeader title="" />
      <h1>{user.username}</h1>
      <div className="league--header">
        <div>
          <img
            src={user.avatar || defaultAvatar}
            alt={user.username}
            className="league--blazon"
          />
        </div>
        <div>
          <PointWrapper>
            <Title>Points</Title>
            <Points>{user.score}</Points>
          </PointWrapper>
          <BonusWrapper>
            <Title>Bonus</Title>
            <UserBonus user={user} />
          </BonusWrapper>
        </div>
      </div>
      <UserBets user={user} />
      <div className="leagues--list__complete">
        {leagues.length > 0 ? (
          <div>
            <h2>
              {id === "me" ? "Mes leagues" : "Leagues de " + user.username}
            </h2>
            {league_list}
          </div>
        ) : (
          <LottieLoader />
        )}
      </div>
    </div>
  );
}
