import { baseEndpoints } from "./base";

export const APIUsers = baseEndpoints("users");

export interface userData {
  id: string;
  fullName: string;
  username: string;
  score: number;
  avatar: string;
  bonus: [];
}
