import React from "react";

import {Link, useLocation} from "react-router-dom";

import DefsSVG from "../../assets/images/defs.svg";
import SvgManager from "../../utils/svg_manager";

export default function Navigation() {
    const location = useLocation();
    const current_path = location.pathname;
    const navigation_items: any = {
        item_2: {
            link: "/leagues",
            name: "Leagues",
            class: "menu__el--valves",
            icon_id: "#feather-bookmark",
            svg_param: {},
        },
        item_3: {
            link: "/matches",
            name: "Matches",
            class: "menu__el--horaires",
            icon_id: "#feather-calendar",
            svg_param: {},
        },
    };

    const navigation = Object.keys(navigation_items).map((item: string) => (
        <li
            key={item}
            className={`menu__el ${navigation_items[item].class} ${
                current_path.startsWith(navigation_items[item].link) ||
                (current_path === "/" && navigation_items[item].link === "/home")
                    ? "menu__el--active"
                    : ""
            }`}
        >
            <Link to={navigation_items[item].link}>
                <SvgManager
                    src={DefsSVG + navigation_items[item].icon_id}
                    {...navigation_items[item].svg_param}
                />
                {navigation_items[item].name}
            </Link>
        </li>
    ));

    return (
        <div>
            <nav className="menu">
                <ul className="menu__liste">
                    <li
                        className={`menu__el menu__el--activites ${
                            current_path === "/" || current_path === "/home"
                                ? "menu__el--active"
                                : ""
                        }`}
                    >
                        <a href="/home">
                            <svg
                                className="icon"
                                width="22px"
                                height="21px"
                                viewBox="0 0 22 21"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                                <g
                                    id="Page-1"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                >
                                    <path
                                        d="M11.5281941,0.468814655 L21.614401,10.5550216 C21.8280791,10.7690733 21.8919584,11.0899641 21.7765274,11.3693894 C21.6607228,11.6488147 21.3883952,11.8303664 21.0861826,11.8303664 L19.5919297,11.8303664 L19.5919297,20.0487572 C19.5919297,20.461171 19.2572171,20.7958836 18.8448033,20.7958836 C18.4323895,20.7958836 18.0976768,20.461171 18.0976768,20.0487572 L18.0976768,11.0832399 C18.0976768,10.6708261 18.4323895,10.3361135 18.8448033,10.3361135 L19.2826194,10.3361135 L10.9999757,2.05346983 L2.71733203,10.3361135 L3.15514812,10.3361135 C3.56756191,10.3361135 3.90227456,10.6708261 3.90227456,11.0832399 L3.90227456,20.0487572 C3.90227456,20.461171 3.56756191,20.7958836 3.15514812,20.7958836 C2.74273433,20.7958836 2.40802168,20.461171 2.40802168,20.0487572 L2.40802168,11.8303664 L0.913768814,11.8303664 C0.611556171,11.8303664 0.339228585,11.6488147 0.223423987,11.3693894 C0.107992952,11.0899641 0.171872263,10.7690733 0.385550424,10.5550216 L10.4717573,0.468814655 C10.7631366,0.177061782 11.2368148,0.177061782 11.5281941,0.468814655 Z M13.241355,12.5774928 C13.6537688,12.5774928 13.9884814,12.9122055 13.9884814,13.3246193 L13.9884814,20.0487572 C13.9884814,20.461171 13.6537688,20.7958836 13.241355,20.7958836 C12.8289412,20.7958836 12.4942286,20.461171 12.4942286,20.0487572 L12.4942286,14.0717457 L9.50572283,14.0717457 L9.50572283,20.0487572 C9.50572283,20.461171 9.17101019,20.7958836 8.7585964,20.7958836 C8.3461826,20.7958836 8.01146996,20.461171 8.01146996,20.0487572 L8.01146996,13.3246193 C8.01146996,12.9122055 8.3461826,12.5774928 8.7585964,12.5774928 L13.241355,12.5774928 Z"
                                        id="Combined-Shape"
                                        fill="#D8D8D8"
                                    ></path>
                                </g>
                            </svg>
                            Accueil
                        </a>
                    </li>
                    {navigation}
                    <li className="menu__el">
                        <a href="/apps">
                            <SvgManager src={DefsSVG + "#feather-download"}/>
                            Apps
                        </a>
                    </li>
                    <li className="menu__el">
                        <a
                            href="http://www.leetchi.com/c/friendsleague"
                            target="_blank"
                            rel="noreferrer"
                            className="basic--link"
                        >
                            Dons
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
