import React from "react";

import {motion} from "framer-motion";

import LeagueList from "../league/list";
import League from "../league/retrieve";
import ContentHeader from "../structure/content_header";

export default function Leagues({match}: any) {
    const league_id = match.params.id;
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 1}}
        >
            <ContentHeader title="Leagues"/>
            {league_id ? <League id={league_id}/> : <LeagueList item_size="big"/>}
        </motion.div>
    );
}
