import React from "react";
import SvgManager from "../../utils/svg_manager";
import DefsSVG from "../../assets/images/defs.svg";

export default function Footer() {
    return (
        <footer>
            <p>
                Copyright 2024 @{" "}
                <a
                    href="https://web-cloud.be"
                    target="_blank"
                    rel="noreferrer"
                    className="basic--link"
                >
                    Webcloud
                </a>{" "}
                -{" "}
                <a href="/support" className="basic--link">
                    Support
                </a>{" "}
                -{" "}
                <a href="/rules" className="basic--link">
                    Règles du jeu
                </a>
                {" "}-{" "}
                <a href="/privacy_policy" className="basic--link">
                    Privacy policy
                </a>
                {" "}-{" "}
                <a
                    href="http://www.leetchi.com/c/friendsleague"
                    target="_blank"
                    rel="noreferrer"
                    className="basic--link"
                >
                    Dons
                </a>
            </p>
        </footer>
    );
}
