import React, {useEffect, useState} from "react";
import {APIUsers} from "../../services/api/users";
import BetTile from "../bet/tile";


export default function UserBets({user}: any) {
    const [bets, setBets] = useState([]);
    useEffect(() => {
        APIUsers.index("/" + user.id + "/bets?ordering=match_date").then((response: any) => {
            setBets(response);
        }).catch((error: any) => {
        });
    }, [user]);

    // Empty array to run effect only once
    const bets_list = bets?.map((bet, key): any => {
        return <BetTile key={key} bet={bet}/>;
    });
    return (
        <div>
            <h2>Paris de {user.username}</h2>
            <div className="horizontal--container">
                <div className="matches--list">{bets_list}</div>
            </div>
        </div>
    );
}