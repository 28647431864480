import React from "react";

import {motion} from "framer-motion";
import ContentHeader from "../structure/content_header";

export default function Support() {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 1}}
        >
            <ContentHeader title="Privacy policy"/>
            <div>

                <h2>Introduction</h2>
                <p>Cette politique de confidentialité décrit comment Friends League collecte,
                    utilise, et partage les informations des utilisateurs enregistré sur l'application. Elle s'applique
                    spécifiquement à l'application listée sur Google Play sous le nom de Friends League.</p>

                <h2>Responsable de la Confidentialité</h2>
                <p>Entité responsable : Friends League<br/>
                    Contact : <a href="mailto:support@friendsleague.be"
                                 className="basic--link">support@friendsleague.be</a></p>

                <h2>Collecte et Utilisation des Données</h2>

                <h3>Types de Données Collectées</h3>
                <p>Notre application accède, collecte, et utilise les types de données personnelles suivants :</p>
                <ul>
                    <li>Adresse email.</li>
                    <li>Pseudo.</li>
                </ul>
                <p>Les mots de passe sont cryptés et ne sont jamais stockés en clair.</p>

                <h3>Utilisation des Données</h3>
                <p>Les données collectées sont utilisées uniquement pour :</p>
                <ul>
                    <li>Les fonctionnalités internes de l'application.</li>
                    <li>La communication avec les utilisateurs concernant l'application (mises à jour, notifications,
                        support).
                    </li>
                </ul>

                <h3>Partage des Données</h3>
                <p>Nous ne partageons pas les données personnelles et sensibles avec des tiers. Toutes les données sont
                    utilisées exclusivement au sein de l'application et pour les communications directement liées à
                    l'application.</p>

                <h2>Sécurité des Données</h2>
                <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger les données personnelles
                    contre l'accès non autorisé, l'altération, la divulgation ou la destruction. Les mots de passe sont
                    cryptés et sécurisés.</p>

                <h2>Conservation et Suppression des Données</h2>

                <h3>Politique de Conservation</h3>
                <p>Nous conservons les données personnelles aussi longtemps que nécessaire pour atteindre les objectifs
                    décrits dans cette politique de confidentialité, sauf si une période de conservation plus longue est
                    requise ou permise par la loi.</p>

                <h3>Suppression des Données</h3>
                <p>Les utilisateurs peuvent demander la suppression de leurs données personnelles en contactant notre
                    point de contact. Nous nous engageons à répondre à ces demandes dans les
                    meilleurs délais.</p>

                <h2>Contact</h2>
                <p>Pour toute question ou demande concernant cette politique de confidentialité, veuillez nous contacter
                    à : <a href="mailto:support@friendsleague.be" className="basic--link">support@friendsleague.be</a>
                </p>

                <h2>Mise à Jour de la Politique</h2>
                <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute
                    modification sera publiée sur cette page et sera effective dès sa mise en ligne.</p>
            </div>
        </motion.div>
    );
}
