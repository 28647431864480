import React, { ReactNode, ReactPortal, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as IconXCircle } from "../../../assets/images/icon-x.svg";

const Overlay = styled(motion.div)`
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 300;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(145, 39, 70, 0.7);
`;
const Container = styled.div<{
  width?: string;
  height?: string;
}>`
  width: ${({ width }) => width ?? "750px"};
  height: ${({ height }) => height ?? "auto"};
  max-width: 90%;
  margin: 32px auto;
  border-radius: 5px;
  background: #ffffffff;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
  overflow-y: auto;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d7d7;
    border-radius: 10px;
  }
`;
const Header = styled.div`
  position: relative;
`;
const CloseIcon = styled(IconXCircle)`
  stroke: #020202;
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border-radius: 5px;

  &:hover {
    background-color: #d9d7d7;
  }

  @media (max-width: 500px) {
    width: 16px;
    height: 16px;
  }
`;
const Content = styled.div``;

const Portal = ({ children }: { children: ReactNode }) =>
  createPortal(children, document.body) as ReactPortal;

interface ModalProps {
  title?: string;
  width?: string;
  height?: string;
  onClose?: () => void;
  children: ReactNode;
}

export default function Modal({
  width,
  height,
  children,
  onClose,
}: ModalProps) {
  useEffect(() => {
    // Disable scrolling of the background when modal is open
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Portal>
      <Overlay>
        <Container width={width} height={height}>
          <Header>{onClose && <CloseIcon onClick={onClose} />}</Header>
          <Content>{children}</Content>
        </Container>
      </Overlay>
    </Portal>
  );
}
