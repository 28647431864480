import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AuthUser from "../../services/swr/user";
import {APILeagues} from "../../services/api/leagues";
import ContentHeader from "../structure/content_header";

export default function LeagueForm() {
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [price, setPrice] = useState("");
    const [password, setPassword] = useState("");
    const [pseudo, setPseudo] = useState("");
    const [blazon, setBlazon] = useState("");
    const history = useHistory();
    const {auth_user, isLoading, isError} = AuthUser();

    useEffect(() => {
        if (!isLoading && isError) {
            history.push("/login");
        } else if (!isLoading) {
            setPseudo(auth_user.username);
        }
    }, [auth_user, isLoading, isError, history]);

    const create_league = (e: React.SyntheticEvent) => {
        e.preventDefault();
        let form_data = new FormData();
        form_data.append("blazon", blazon);
        form_data.append("name", name);
        form_data.append("password", password);
        form_data.append("price", price);
        form_data.append("pseudo", pseudo);

        APILeagues.create(form_data)
            .then((response: any) => {
                history.push("/leagues/" + response.id);
            })
            .catch((error: any) => {
                if (error.response) {
                    // Request made and server responded
                    setMessage(error.response.data.detail);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            });
    };

    const handleChangePrice = (e: any) => {
        setPrice(e.target.value);
    };

    const handleChangePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    };

    const handleChangeBlazon = (e: any) => {
        setBlazon(e.target.files[0]);
    };

    const handleChangePseudo = (e: any) => {
        setPseudo(e.target.value);
    };

    return (
        <div>
            <ContentHeader title="Créer une league"/>
            {message && <p className="login--error_message">{message}</p>}
            <form onSubmit={create_league} className="basic--form">
                <input
                    type="text"
                    placeholder="Nom de la league"
                    name="name"
                    required
                    onChange={handleChangeName}
                />
                <input
                    type="text"
                    placeholder="Prix mis en jeu"
                    name="price"
                    onChange={handleChangePrice}
                />
                <input
                    type="text"
                    placeholder="pseudo dans la league"
                    name="pseudo"
                    value={pseudo}
                    autoComplete="new-password"
                    required
                    onChange={handleChangePseudo}
                />
                <input
                    type="file"
                    placeholder="blazon"
                    name="blazon"
                    required
                    onChange={handleChangeBlazon}
                />
                <p>
                    Le mot de passe n'est pas obligatoire. Il n'est à remplir que si vous
                    désirez créer une league privée.
                </p>
                <input
                    type="password"
                    placeholder="password"
                    autoComplete="new-password"
                    onChange={handleChangePassword}
                />
                <input type="password" placeholder="confirmation du password"/>
                <button className="basic--btn add--bet">Créer ma league</button>
            </form>
        </div>
    );
}
