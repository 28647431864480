import AuthUser from "../../services/swr/user";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthLogout } from "../../services/api/auth";
import Dropdown from "../../shared/ui/Dropdown";
import styled from "styled-components";
import { ReactComponent as User } from "../../assets/images/user.svg";
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import { ReactComponent as Logout } from "../../assets/images/logout.svg";
import { ReactComponent as Rule } from "../../assets/images/documents.svg";

const Container = styled.div`
  position: relative;
`;
const Wrapper = styled.div`
  display: block;
  margin-left: 20px;
  background: white;
  border-radius: 30px;
  padding: 5px;
  line-height: 0;
  cursor: pointer;
`;
const UserScore = styled.span`
  color: var(--color-text-second);
  margin: 18px 10px 0 10px;
  display: block;
  float: left;
`;
const LogoImg = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;
const Items = styled.li`
  padding: 12px;
  color: #020202;
  transition: all linear 0.3s;

  &:not(:first-child):hover {
    background-color: rgba(145, 39, 70, 0.7);
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #430046;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
const Item = styled.div<{ $noCursor?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: ${({ $noCursor }) => ($noCursor ? "default" : "pointer")};
`;
const ItemText = styled.div`
  margin: 0 0 0 6px;
`;
const ProfileWrapper = styled.div``;
const UserName = styled(ItemText)`
  font-size: 16px;
  font-weight: 600;
`;
const Email = styled(ItemText)`
  font-size: 14px;
`;
const Icon = styled.svg`
  width: 20px;
  height: 20px;
  fill: currentColor;
`;

export default function DropdownProfile() {
  const { auth_user } = AuthUser();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();

  const logout = () => {
    AuthLogout();
    history.push("/home");
  };

  const DropdownContent = () => {
    return (
      <>
        <Items>
          <Item key="profile-info" $noCursor>
            <LogoImg
              className="menuTop__profil"
              src={auth_user.avatar}
              alt={auth_user.username}
            />
            <ProfileWrapper>
              <UserName>{auth_user.username}</UserName>
              <Email>{auth_user.email}</Email>
            </ProfileWrapper>
          </Item>
        </Items>
        <Items>
          <Item
            key="profile"
            onClick={() => history.push("/user/" + auth_user.id)}
          >
            <Icon as={User} />
            <ItemText>Mon profil</ItemText>
          </Item>
        </Items>
        <Items>
          <Item key="edit" onClick={() => history.push("/user/edit")}>
            <Icon as={Edit} />
            <ItemText>Editer</ItemText>
          </Item>
        </Items>
        <Items>
          <Item key="rule" onClick={() => history.push("/rules")}>
            <Icon as={Rule} />
            <ItemText>Règles du jeu</ItemText>
          </Item>
        </Items>
        <Items>
          <Item key="logout" onClick={logout}>
            <Icon as={Logout} />
            <ItemText>Se déconnecter</ItemText>
          </Item>
        </Items>
      </>
    );
  };
  return (
    <Container>
      <div
        className="menuTop__el menuTop__el--profil"
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      >
        <Wrapper>
          <UserScore>{auth_user.score} pts</UserScore>
          <LogoImg
            className="menuTop__profil"
            src={auth_user.avatar}
            alt={auth_user.username}
          />
        </Wrapper>
        {isDropdownOpen && (
          <Dropdown
            children={<DropdownContent />}
            onClose={() => setIsDropdownOpen(false)}
          />
        )}
      </div>
    </Container>
  );
}
