import React, {useEffect, useState} from "react";
import {APIMatches, defaultMatchData, matchStatus} from "../../services/api/matches";

interface BetTileProps {
    bet: {
        game: string;
        scoreTeamA: number;
        scoreTeamB: number;
        points: number;
    };
    with_result?: boolean;
}

export default function BetTile({bet, with_result = true}: BetTileProps) {
    const [match, setMatch] = useState(defaultMatchData);

    useEffect(() => {
        APIMatches.single(bet.game)
            .then((response: any) => {
                setMatch(response);
            })
            .catch((error: any) => {
                console.error("Error fetching match data:", error);
            });
    }, [bet.game]);

    return (
        <>
            {match !== undefined ? (
                <div
                    className={
                        "match--tile match--tiny_tile" +
                        (matchStatus[match.status] === "playing" ? " live" : "")
                    }
                >
                    <div className="match--teams">
                        <div className="team team--a">
                            <img src={match.teamA.blazon} alt={match.teamA.name}/>
                        </div>
                        <div className="match--score">
                            {bet.scoreTeamA} - {bet.scoreTeamB}
                        </div>
                        <div className="team team--b">
                            <img src={match.teamB.blazon} alt={match.teamB.name}/>
                        </div>
                    </div>
                    {with_result ? (
                        <div className="bet--result">
                            <span className="bet--points">{bet.points} pts</span>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                <div>No match</div>
            )}
        </>
    );
}
