import React, { useEffect, useState } from "react";
import {
  defaultTeamData,
  matchStatus,
  teamData,
} from "../../services/api/matches";
import MatchBetCTA from "./bet_cta";
import { formatDate } from "../../utils/date";
import MatchModal from "./MatchModal";

export default function MatchItem({ match }: any) {
  const [team_a, setTeamA] = useState<teamData>(defaultTeamData);
  const [team_b, setTeamB] = useState<teamData>(defaultTeamData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTeamA(match.teamA);
    setTeamB(match.teamB);
  }, [match]);

  const handleOpenModal = () => {
    if (matchStatus[match.status] === "waiting") {
      setIsModalOpen(true);
    }
  };

  const match_date = new Date(match.matchDate);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    window.location.reload(); // TODO check for a better way to reload the data
  };

  return (
    <div
      className={
        "match--list_item" +
        (matchStatus[match.status] === "playing" ? " live" : "")
      }
      onClick={handleOpenModal}
    >
      <div className="match--teams">
        <div className="team team--a">
          <img src={team_a.blazon} alt={team_a.name} />
          <span>{team_a.name.substring(0, 3).toUpperCase()}</span>
        </div>
        <div className="team team--b">
          <img src={team_b.blazon} alt={team_b.name} />
          <span>{team_b.name.substring(0, 3).toUpperCase()}</span>
        </div>
      </div>
      <div className="match--score">
        <span
          className={`${
            matchStatus[match.status] !== "waiting" &&
            matchStatus[match.status] !== "finished"
              ? "live"
              : ""
          }`}
        >
          {match.scoreTeamA} : {match.scoreTeamB}
        </span>
      </div>
      <div className="match--date">
        <span>
          {formatDate(match_date) +
            " " +
            match_date.getHours() +
            ":" +
            (match_date.getMinutes() < 10 ? "0" : "") +
            match_date.getMinutes()}
        </span>
      </div>
      <MatchBetCTA match={match} />
      {isModalOpen && (
        <MatchModal matchId={match.id} onClose={handleCloseModal} />
      )}
    </div>
  );
}
