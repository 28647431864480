import React, { useEffect, useRef } from "react";
import Lottie from "react-lottie";
import animationData from "../assets/json/ball_loader.json";

interface LottieLoaderProps {
  color?: string;
}

export default function LottieLoader({ color = "#fff" }: LottieLoaderProps) {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainer.current) {
      const svgElement = animationContainer.current.querySelector("svg");
      if (svgElement) {
        const paths = svgElement.querySelectorAll("path");
        paths.forEach((path) => {
          path.setAttribute("stroke", color);
          path.setAttribute("fill", color);
        });
      }
    }
  }, [color]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div ref={animationContainer}>
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
}
