import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import React from "react";

export default function LeagueShare({league}: any) {
    return (
        <div className="league--share">
            <EmailShareButton
                url={window.location.href}
                title={'Rejoins moi dans la league "' + league.name + '"'}
                subject={'Rejoins moi dans la league "' + league.name + '"'}
                body={
                    "J'ai rejoins la league \"" +
                    league.name +
                    '" sur le jeu Friends League. Rejoins-y moi!'
                }
                separator=" -> "
            >
                <EmailIcon size="32" round={true}/>
            </EmailShareButton>
            <FacebookShareButton
                title={'Rejoins moi dans la league "' + league.name + '"!'}
                url={window.location.href}
            >
                <FacebookIcon size="32" round={true}/>
            </FacebookShareButton>
            <WhatsappShareButton
                title={'Rejoins moi dans la league "' + league.name + '"!'}
                url={window.location.href}
                separator=" -> "
            >
                <WhatsappIcon size="32" round={true}/>
            </WhatsappShareButton>
        </div>
    );
}
