import React from "react";

export default function MatchOdd({ match }: any) {
  return (
    <div>
      {match.oddsVictoryA ? (
        <div className="match--odds">
          <p>
            Victoire
            <span>{match.oddsVictoryA}</span>
          </p>
          <p>
            Egalité
            <span>{match.oddsDraw}</span>
          </p>
          <p>
            Victoire
            <span>{match.oddsVictoryB}</span>
          </p>
        </div>
      ) : (
        <div className="match--odds">
          <p>Cotes du match à venir</p>
        </div>
      )}
    </div>
  );
}
