import React from "react";

import Login from "./login";

interface IProps {
  title: string;
}

export default function ContentHeader({ title }: IProps) {
  return (
    <div className="page__header">
      {title && <h1 className="title title--big">{title}</h1>}
      <div className="menuTop">
        <Login />
      </div>
    </div>
  );
}
