import React, {useState} from "react";
import {apiClient} from "../../services/api/base";
import {removeToken} from "../../utils/storage";

export default function CreateResetPasswordToken() {
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");

    const reset_password_token = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setMessage("");
        removeToken();
        apiClient
            .post("auth/password_tokens", {
                email: email
            })
            .then((response: any) => {
                setMessage('Un email vous sera envoyé, veuillez suivre les instructions indiquées')
            })
            .catch((error: any) => {
                if (error.response) {
                    // Request made and server responded
                    setMessage(error.response.data.detail);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            });
    };

    const handleChangeEmail = (e: any) => {
        setEmail(e.target.value);
    };


    return (
        <div>
            <h1>Modification de mot de passe</h1>
            <p>Veuillez encoder l'email du compte dont vous voulez changer le mot de passe</p>
            {message && <p className="error--msg">{message}</p>}
            <form onSubmit={reset_password_token} className="basic--form">
                <input
                    type="email"
                    name="email"
                    placeholder="email"
                    value={email}
                    onChange={handleChangeEmail}
                />
                <button className="basic--btn add--bet">Envoyer</button>
            </form>

            <a href="/login" className="basic--link auth--signin--link">
                Mot de passe retrouvé!
            </a>
        </div>
    );
}
