import {baseEndpoints} from "./base";

export const APIBets = baseEndpoints("bets");

export interface betData {
    id: string;
    scoreTeamA: number;
    scoreTeamB: number;
    bonus: string;
    bonusType: number;
    points: number;
    game: string;
}

export const defaultBetData = {
    id: "",
    scoreTeamA: 0,
    scoreTeamB: 0,
    bonus: "",
    bonusType: 0,
    points: 0,
    game: "string"
};

export const bonusEmoji = ["🚫", "2️⃣", "3️⃣", "4️⃣"];
