import { MatchData } from "../../services/api/matches";
import { formatDate } from "../../utils/date";
import MatchItem from "./item";
import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  margin: 16px 0 8px 0;
`;

interface MatchListProps {
  matches: MatchData[];
}

const groupMatchesByDate = (matches: MatchData[]) => {
  const groupedMatches = matches.reduce(
    (acc: Record<string, MatchData[]>, match) => {
      const date = match.matchDate.toString().split("T")[0];
      acc[date] = [...(acc[date] || []), match];
      return acc;
    },
    {}
  );
  return Object.entries(groupedMatches);
};

export default function MatchList({ matches }: MatchListProps) {
  const groupedMatches = groupMatchesByDate(matches);

  return (
    <>
      {groupedMatches.map(([date, matchesByDate]) => (
        <div key={date}>
          <Title>{formatDate(new Date(date))}</Title>
          {matchesByDate.map((match: any, index: number) => (
            <MatchItem key={index} match={match} />
          ))}
        </div>
      ))}
    </>
  );
}
