import React from "react";

import {motion} from "framer-motion";
import ContentHeader from "../structure/content_header";
import AppleStoreImg from "../../assets/images/csm_app-store-badge_6f74703f88.png";
import GooglePlayImg from "../../assets/images/google-play-badge.png";

export default function Apps() {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 1}}
        >
            <ContentHeader title="Applications"/>
            <h2>Pour télécharger notre applications</h2>
            <p style={{textAlign: "center"}}>
                <a
                    href="https://apps.apple.com/be/app/friends-league/id1570607506?l=fr"
                    target="_blank"
                    rel="noreferrer"
                    className="basic--link"
                >
                    <img src={AppleStoreImg} alt="Apple store" width="250"/>
                </a>
            </p>
            <p>L'app android est bientôt disponible!</p>
        </motion.div>
    );
}

/*
<p style={{textAlign: "center"}}>
    <a
        href="https://play.google.com/store/apps/details?id=com.friendleague.friendleague"
        target="_blank"
        rel="noreferrer"
        className="basic--link"
    >
        <img src={GooglePlayImg} alt="Apple store" width="250"/>
    </a>
</p>*/