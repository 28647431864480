import React from "react";
import AuthUser from "../../services/swr/user";
import DropdownProfile from "./DropdownProfile";

export default function Login() {
  const { auth_user, isLoading, isError } = AuthUser();

  if (isLoading === null) {
    return <li>Chargement...</li>;
  }
  if (!isLoading && !isError && auth_user) {
    return <DropdownProfile />;
  }

  return (
    <li>
      <a href="/login" id="login_btn" className="basic--btn login--link">
        <span className="">Se connecter</span>
      </a>
    </li>
  );
}
