import {motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import {APIMatches, MatchData} from "../../services/api/matches";
import MatchTile from "./tile";
import MatchTinyTile from "./tiny_tile";

function MatchTileChoice(tile_type: any, key: number, local_match: MatchData) {
    if (tile_type === "tiny") {
        return <MatchTinyTile key={key} match={local_match}/>;
    }
    return <MatchTile key={key} match={local_match}/>;
}

export default function MatchTileList({type, tiles_type, title}: any) {
    const [matches, setMatches] = useState([]);
    useEffect(() => {
        let ordering = type === "results" ? "&ordering=-match_date" : "";
        APIMatches.index("?type=" + type + ordering).then((response: any) => {
            if (response) {
                setMatches(response);
            }
        });
    }, [type]);

    const matches_list = matches.map((match: MatchData, key) =>
        MatchTileChoice(tiles_type, key, match)
    );

    return (
        <>
            {matches_list.length > 0 ? (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 1}}
                >
                    <h2>{title}</h2>
                    <div className="horizontal--container">
                        <div className="matches--list">{matches_list}</div>
                    </div>
                </motion.div>
            ) : null}
        </>
    );
}
