import React, { useEffect, useState } from "react";
import { APIMatches, matchStatus } from "../../services/api/matches";
import { betData, bonusEmoji } from "../../services/api/bets";

export default function MatchBetCTA({ match }: any) {
  const [bet, setBet] = useState<betData | null>(null);

  useEffect(() => {
    APIMatches.index("/" + match.id + "/user_bet")
      .then((response: any) => {
        setBet(response);
      })
      .catch(() => {});
  }, [match]);

  return (
    <div className="bet--result">
      {bet && matchStatus[match.status] === "waiting" && (
        <p className="bet--score">
          {bet.scoreTeamA + " - " + bet.scoreTeamB}{" "}
          {bet.bonusType !== 0 && bonusEmoji[bet.bonusType]}
        </p>
      )}
      {bet && matchStatus[match.status] !== "waiting" && (
        <div>
          <p className="bet--score--after">
            🎯 {bet.scoreTeamA + " - " + bet.scoreTeamB}
          </p>
          <p className="bet--points">{bet.points} pts</p>
        </div>
      )}
      {bet === null && matchStatus[match.status] !== "waiting" && (
        <div>
          <p>Trop tard</p>
        </div>
      )}
      {bet === null && matchStatus[match.status] === "waiting" && (
        <button className="basic--btn">Parier</button>
      )}
    </div>
  );
}
